import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalStorageService } from '../services/local-storage-service';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(
    translate: TranslateService,
    private localStorageService: LocalStorageService,
    ) {
    translate.addLangs(['en', 'ar']);
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    let lang = this.localStorageService.getItem('lang');
    if(lang === undefined || lang === null || lang === ''){
      this.localStorageService.setItem('lang',  'en');
      this.localStorageService.setItem('direction',  'ltr');
      lang = 'en';
    }
    translate.use(lang);
  }
}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}