import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { NgxToastrService } from '../../services/toastr.service';
import { RequestService } from '../../services/request.service';
import { LocalStorageService } from '../../services/local-storage-service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ConfirmedValidator } from '../../helpers/must-match.validator';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
declare const $: any;

@Component({
  selector: 'app-admin_signup',
  templateUrl: './admin_signup.component.html',
  styleUrls: ['./admin_signup.component.css'],
})
export class AdminSignupComponent implements OnInit {
  public options;

  clusterArr;

  form: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  success = '';
  company_id;
  companyEmailAddress;
  companyPassword;
  otp;
  signUpForm=true;
  otpForm=false;

  isLoading: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private toastrService: NgxToastrService,
    public requestService: RequestService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    public router: Router,
  ) {
    this.form = this.formBuilder.group({
      com_name: ['', Validators.required],
      com_country_code: ['1', Validators.required],
      com_mobile_number: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      business_licence: [''],
      com_email: ['', [Validators.required,Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      custom_cluster_ids: ['',Validators.required],
      

    },{ 
      validator: [
        ConfirmedValidator('password', 'confirmPassword')
      ]
    });
  }

  ngOnInit() {
    this.getAllCustomCluster();
    this.options = {
      width: '100%',
      multiple: true,
      tags: false
    };
  }

  toSignIn(){
    this.router.navigate(['admin_login'])
  }

  get f() { return this.form.controls; }

  getAllCustomCluster() {
    this.requestService.getAllCustomCluster().pipe(first()).subscribe({
        next: (data) => {
          if(data.status === 1){
            this.clusterArr = data.data;
          }
        },
        error: error => {
        }
      });
  }

  submitForm() {
    
    this.submitted = true;
    if (this.form.invalid) {
      this.isLoading=false;
      return;
    }
    this.isLoading=true;
    this.loading = true;
    this.requestService.signupCompany(this.form.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          if(data.status === 1){
            this.company_id = data.data._id;
            this.companyEmailAddress = data.data.com_email;
            this.companyPassword = this.form.value.password;
            this.form.reset();
            this.toastrService.showSuccess('Success!', data.message);
            this.signUpForm = false;
            this.isLoading=false;
            this.otpForm = true;
          } else {
            this.toastrService.showError('Error!', data.message);
            this.isLoading=false;
          }
          this.loading = false;
          this.submitted = false;
        },
        error: error => {
          this.isLoading=false;
          this.toastrService.showError('Error!', error);
          this.loading = false;
          this.submitted = false;
        }
      });
  }

  varifyOtpOnSignUp() {
    this.isLoading=true;
    this.requestService.varifyOtpOnSignUp({company_id:this.company_id,otp:this.otp})
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.isLoading=false;
          if(data.status === 1){
            this.toastrService.showSuccess('Success!', data.message);
            this.adminLogin();
            this.isLoading=false;
          } else {
            this.toastrService.showError('Error!', data.message);
          }
        },
        error: error => {
          this.toastrService.showError('Error!', error);
        }
      });
  }

  adminLogin() {
    this.authenticationService.admin_login(this.companyEmailAddress, this.companyPassword)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.router.navigate(['/admin/dashboard']);
          } else {
            this.toastrService.showError('Authentication Error!', data.message);
          }
        },
        error: error => {
          this.toastrService.showError('Authentication Error!', error);
        }
      });
  }

}