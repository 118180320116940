import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, public router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // login(email_id: string, password: string) {
  //     return this.http.post<any>(`${environment.webApiUrl}login`, { email_id, password })
  //         .pipe(map(data => {
  //             if (data.status === 1) {
  //                 localStorage.setItem('token', data.token);
  //                 localStorage.setItem('currentUser',JSON.stringify(data.data));
  //                 localStorage.setItem('role', data.role);
  //                 this.currentUserSubject.next(data.data);
  //                 return data;
  //             } else {
  //                 return data;
  //             }
  //         }));
  // }

  // updateUser(data) {
  //     localStorage.setItem('currentUser',JSON.stringify(data));
  //     this.currentUserSubject.next(data);
  //     return true;
  // }

  super_admin_login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.superAdminApiUrl}login`, { username, password })
      .pipe(
        map((data) => {
          if (data.status === 1) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('currentUser', JSON.stringify(data.data));
            localStorage.setItem('access', JSON.stringify(data.module));
            localStorage.setItem('role', data.role);
            this.currentUserSubject.next(data.data);
            return data;
          } else {
            return data;
          }
        })
      );
  }

  admin_login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.adminApiUrl}login`, { username, password })
      .pipe(
        map((data) => {
          if (data.status === 1) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('currentUser', JSON.stringify(data.data));
            localStorage.setItem('access', JSON.stringify(data.module));
            localStorage.setItem('role', data.role);
            this.currentUserSubject.next(data.data);
            return data;
          } else {
            return data;
          }
        })
      );
  }

  adminUserlogin(username: string, password: string) {
    return this.http
      .post<any>(`${environment.adminApiUrl}companyUserLogin`, { username, password })
      .pipe(
        map((data) => {
          if (data.status === 1) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('currentUser', JSON.stringify(data.data));
            localStorage.setItem('access', JSON.stringify(data.module));
            localStorage.setItem('role', data.role);
            this.currentUserSubject.next(data.data);
            console.log("ddssdfdsf",data)
            return data;
          } else {
            return data;
          }
        })
      );
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    const role = this.getRole();
    localStorage.removeItem('role');
    if (role == 'admin') {
      this.router.navigate(['/admin_login']);
      location.reload();
    }
    else {
      this.router.navigate(['/super_admin_login']);
      location.reload();
    }
  }

  getRole() {
    return localStorage.getItem('role');
  }

  isLoggedIn() {
    const currentUser = this.currentUserSubject.value;
    if (currentUser) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin() {
    const isLoggedIn = this.isLoggedIn();
    if (isLoggedIn) {
      const role = localStorage.getItem('role');
      if (role === 'admin') {
        return true;
      }
    }
    return false;
  }

  isSuperAdmin() {
    const isLoggedIn = this.isLoggedIn();
    if (isLoggedIn) {
      const role = localStorage.getItem('role');
      if (role === 'super_admin') {
        return true;
      }
    }
    return false;
  }

  // isFan(){
  //     const isLoggedIn = this.isLoggedIn();
  //     if(isLoggedIn){
  //         const role = localStorage.getItem("role");
  //         if(role === 'fan'){
  //             return true;
  //         }
  //     }
  //     return false;
  // }
}
