import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './middleware/auth.guard';
import { AdminGuard } from './middleware/admin.guard';
import { SuperAdminGuard } from './middleware/super_admin.guard';

import { SuperAdminLoginComponent } from './component/super_admin/super_admin_login.component';
import { AdminLoginComponent } from './component/admin/admin_login.component';
import { AdminSignupComponent } from './component/admin_signup/admin_signup.component';
import { AboutUsComponent } from './component/pages/about-us/about-us.component';
import { PrivacyAndSecurityComponent } from './component/pages/privacy-and-security/privacy-and-security.component';
import { SupportComponent } from './component/pages/support/support.component';
import { HomeComponent } from './component/home/home.component';
import { SuccessComponent } from './component/pages/payment/success/success.component';
import { CancelComponent } from './component/pages/payment/cancel/cancel.component';
import { TermsConditionComponent } from './component/pages/terms-condition/terms-condition.component';

const routes: Routes = [
  { path: 'index', redirectTo: '', pathMatch: 'full' },
  {
    path:'',
    component:HomeComponent
  },
  {
    path: 'super_admin_login',
    component: SuperAdminLoginComponent
  },
  {
    path: 'admin_login',
    component: AdminLoginComponent
  },
  {
    path: 'admin_signup',
    component: AdminSignupComponent
  },
  {
    path: 'aboutUs',
    component: AboutUsComponent
  },
  {
    path: 'privacy_and_security',
    component: PrivacyAndSecurityComponent
  },
  {
    path: 'terms_condition',
    component: TermsConditionComponent
  },
  {
    path: 'support',
    component: SupportComponent
  },
  {
    path: 'payment/success',
    component: SuccessComponent
  },
  {
    path: 'payment/cancel',
    component: CancelComponent
  },
  {
    path: 'super_admin',
    loadChildren: () =>
      import('./component/super_admin_module/super_admin.module').then((m) => m.SuperAdminModule),
      canActivate: [SuperAdminGuard] // enable van add login functionality
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./component/admin_module/admin.module').then((m) => m.AdminModule),
      canActivate: [AdminGuard] // enable van add login functionality
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      // preloadingStrategy: PreloadAllModules,
      // scrollPositionRestoration: 'top', useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
