import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { NgxToastrService } from '../../services/toastr.service';
import { RequestService } from '../../services/request.service';
import { LocalStorageService } from '../../services/local-storage-service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
declare const $: any;

@Component({
  selector: 'app-admin_login',
  templateUrl: './admin_login.component.html',
  styleUrls: ['./admin_login.component.css'],
  // encapsulation : ViewEncapsulation.None
})
export class AdminLoginComponent implements OnInit {
  loginForm: FormGroup;
  forgetForm: FormGroup;
  loading = false;
  submitted = false;
  isLogin = false;
  isLoginUser = false;
  logInForm = false;
  passwordForm = false;
  error = '';
  success = '';

  userRole:any
  admin_user:any
  admin:any
  role:any

  isAdmin:boolean=false
  isAdminUser:boolean=false

  isLoading: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private toastrService: NgxToastrService,
    public requestService: RequestService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    public router: Router,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required,Validators.email],
      password: ['', Validators.required]
    });

    this.forgetForm = this.formBuilder.group({
      email: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.logInForm = true;
    this.passwordForm = false;
    this.isAdminLogin()
  }

  forget(){
    this.logInForm = false  ;
    this.passwordForm = true;
  }

  get f() { return this.loginForm.controls; }
  get fp() { return this.forgetForm.controls; }

  // onAdmin(event:any) {
  //   this.admin= event.target.value;
  //   console.log("admin",this.admin )
  // }
  // onAdminUser(event:any) {
  //   this.admin_user= event.target.value;
  //   console.log("adminUser",this.admin_user)
  // }

  isAdminLogin(){
    this.isAdmin = true;
    this.isAdminUser = false;
  }
  isAdminUserLogin(){
    this.isAdminUser = true;
    this.isAdmin = false;
  }
  

  toSignUp(){
    this.router.navigate(['admin_signup'])
  }

  forgetPassword(){
    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      this.isLoading = false;
      return;
    }
    
    this.loading = true;
    this.requestService.forgetAdminPassword(this.forgetForm.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.isLoading=false;
            this.logInForm = true;
            this.passwordForm = false;
            this.toastrService.showSuccess('Success', data.message);

          } else {
            this.toastrService.showError('Authentication Error!', data.message);
            this.isLoading=false;
          }
          this.loading = false;
        },
        error: error => {
          this.toastrService.showError('Authentication Error!', error);
          this.loading = false;
        }
      })
  }
  forgetUserPassword(){
    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      this.isLoading = false;
      return;
    }
    
    this.loading = true;
    this.requestService.forgetUserPassword(this.forgetForm.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.isLoading=false;
            this.logInForm = true;
            this.passwordForm = false;
            this.toastrService.showSuccess('Success', data.message);

          } else {
            this.toastrService.showError('Authentication Error!', data.message);
            this.isLoading=false;
          }
          this.loading = false;
        },
        error: error => {
          this.toastrService.showError('Authentication Error!', error);
          this.loading = false;
        }
      })
  }
  adminLogin() {

    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.isLoading = false;
      return;
    }

    this.loading = true;
    this.authenticationService.admin_login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.router.navigate(['/admin/dashboard']);
            this.isLoading = false;
          } else {
            this.toastrService.showError('Authentication Error!', data.message);
            this.isLoading = false;
          }
          this.loading = false;
        },
        error: error => {
          this.toastrService.showError('Authentication Error!', error);
          this.loading = false;
        }
      });
  }

  
  adminUserLogin() {

    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.loading = true;
    this.authenticationService.adminUserlogin(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.router.navigate(['/admin/dashboard']);
            this.isLoading = false;
          } else {
            this.toastrService.showError('Authentication Error!', data.message);
            this.isLoading = false;
          }
          this.loading = false;
        },
        error: error => {
          this.toastrService.showError('Authentication Error!', error);
          this.loading = false;
        }
      });
  }
};
