import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NgxToastrService {
    constructor(
        private toastrService: ToastrService,
    ) {
    }
    public showSuccess(message:string, title:string): void {
        this.toastrService.success(title, message);
    }
    
    public showInfo(message:string, title:string): void {
    this.toastrService.info(title, message);
    }

    public showWarning(message:string, title:string): void {
    this.toastrService.warning(title, message);
    }

    public showError(message:string, title:string): void {
    this.toastrService.error(title, message);
    }
}