import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  AfterViewChecked, AfterViewInit,
} from '@angular/core';
import {
  Event,
  NavigationStart,
  Router,
} from '@angular/router';
import { LocalStorageService } from './services/local-storage-service';
// import { initializeApp } from "firebase/app";
import { environment } from "../environments/environment";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewChecked, AfterViewInit {

  url;
  loadFooter = false;
  show: boolean = true;
  isLoading: boolean = true;
  hideFooter: boolean = false;
  splitVal;
  base = '';
  page = '';
  dir = '';

  title = 'af-notification';
  message:any = null;
  constructor(
    private changeDetector: ChangeDetectorRef,
    public router: Router,
    private localStorageService: LocalStorageService,
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
        if (this.base === 'admin') {
          this.show = false;
          this.hideFooter = true;
        } else {
          if (event.url === '/message') {
            this.hideFooter = true;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.dir = this.localStorageService.getItem('direction');
    setTimeout(() => (this.loadFooter = true), 2000);
    // this.requestPermission();
    // this.listen();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  // requestPermission() {
  //   const app = initializeApp(environment.firebase);
  //   const messaging = getMessaging(app);
  //   getToken(messaging, 
  //    { vapidKey: environment.firebase.vapidKey}).then(
  //      (currentToken) => {
  //        if (currentToken) {
  //          console.log("Hurraaa!!! we got the token.....");
  //          console.log(currentToken);
  //        } else {
  //          console.log('No registration token available. Request permission to generate one.');
  //        }
  //    }).catch((err) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //   });
  // }
  // listen() {
  //   const app = initializeApp(environment.firebase);
  //   const messaging = getMessaging(app);
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     this.message=payload;
  //   });
  // }
}
