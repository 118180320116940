import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { NgxToastrService } from '../../services/toastr.service';
import { RequestService } from '../../services/request.service';
import { LocalStorageService } from '../../services/local-storage-service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
declare const $: any;

@Component({
  selector: 'app-super_admin_login',
  templateUrl: './super_admin_login.component.html',
  styleUrls: ['./super_admin_login.component.css'],
})
export class SuperAdminLoginComponent implements OnInit {
  loginForm: FormGroup;
  forgetForm: FormGroup;

  logInForm = false;
  passwordForm = false;
  loading = false;
  submitted = false;
  error = '';
  success = '';

  isLoading: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private toastrService: NgxToastrService,
    public requestService: RequestService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    public router: Router,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.forgetForm = this.formBuilder.group({
      email: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.logInForm = true;
    this.passwordForm = false;
  }

  forget(){
    this.logInForm = false  ;
    this.passwordForm = true;
  }

  get f() { return this.loginForm.controls; }
  get fp() { return this.forgetForm.controls; }

  forgetPassword(){
    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      this.isLoading = false;
      return;
    }

    this.loading = true;
    console.log(this.forgetForm.value,"this.forgetForm.value")
    this.requestService.forgetPassword(this.forgetForm.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.isLoading=false;
            this.logInForm = true  ;
            this.passwordForm = false;
            this.toastrService.showSuccess('success!', data.message);
          } else {
            this.toastrService.showError('Authentication Error!', data.message);
            this.isLoading=false;
          }
          this.loading = false;
        },
        error: error => {
          this.toastrService.showError('Authentication Error!', error);
          this.loading = false;
        }
      })
  }



  superAdminLogin() {
    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.isLoading = false;
      return;
    }

    this.loading = true;
    this.authenticationService.super_admin_login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.router.navigate(['/super_admin/dashboard']);
            this.isLoading=false;
          } else {
            this.toastrService.showError('Authentication Error!', data.message);
            this.isLoading=false;
          }
          this.loading = false;
        },
        error: error => {
          this.toastrService.showError('Authentication Error!', error);
          this.loading = false;
        }
      });
  }
}
