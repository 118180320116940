import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage-service';

@Injectable()
export class LangInterceptor implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService,
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lang = this.localStorageService.getItem('lang');
    const modifiedReq = req.clone({ 
      headers: req.headers.set('lang', lang),
    });
    return next.handle(modifiedReq);
  }
}