

<section id="wrapper" class="login-register">
	<div class="box" *ngIf="!passwordForm">
		<h4>Teacher Sign In</h4>
		<div>
			<div class="pull-left">
				<input type="radio" name="admin"  value="admin" (click)="isAdminLogin()" checked="checked">
				<label style="color: #3DF712;" for="">&nbsp;&nbsp;Login as Admin</label>
			</div>
			<div class="pull-right">
				<input type="radio" name="admin" value="admin_user" (click)="isAdminUserLogin()">
				<label style="color: #3DF712;" for="">&nbsp;&nbsp;Login as Admin User</label>
			</div>
		</div>
		<br/>
		<br/>
		<br/>
		<form [formGroup]="loginForm">
			<div class="inputBox">
				<input type="text" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Email</div>
                </div>
				<label for="">Email</label>
			</div>
			<div class="inputBox">
				<input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Password</div>
                </div>
				<label for="">Password</label>
			</div>
			<!-- <h4><button type="submit" *ngIf="!isAdminUser && !isAdmin" (click)="adminLogin()"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div> <b>Submit</b></button></h4> -->
			<p class="text-center"><a class="color" (click)="forget()">Forget Password ?</a></p>

			<p class="text-center color">Don't have an account? <a class="signUp" (click)="toSignUp()"><b>Sign Up</b></a></p>
			<h4><button type="submit" *ngIf="isAdmin" (click)="adminLogin()"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div> <b>Submit</b></button></h4>
			<h4><button type="submit" *ngIf="isAdminUser" (click)="adminUserLogin()"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div> <b>Submit</b></button></h4>
			
			<div style="text-align: center">
				<img src="assets/img/NSFLogo.png" alt="user" width="70px">
			</div>
		</form>
	</div>
	<div *ngIf="passwordForm" class="box">
		<h4>Forget Password.</h4>
		<div>
			<div class="pull-left">
				<input type="radio" name="admin"  value="admin" (click)="isAdminLogin()" checked="checked">
				<label style="color: #3DF712;" for="">&nbsp;&nbsp;Login as Admin</label>
			</div>
			<div class="pull-right">
				<input type="radio" name="admin" value="admin_user" (click)="isAdminUserLogin()">
				<label style="color: #3DF712;" for="">&nbsp;&nbsp;Login as Admin User</label>
			</div>
		</div>
		<br/>
		<br/>
		<br/>
		<form [formGroup]="forgetForm" >
			<div class="inputBox">
				<input type="text" formControlName="email" placeholder="Please enter registered email" [ngClass]="{ 'is-invalid': submitted && fp.email.errors }">
                <div *ngIf="submitted && fp.email.errors" class="invalid-feedback">
                    <div *ngIf="fp.email.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Email</div>
                </div>
				<label for="">Email</label>
				<h4><button style="width: 48%;" *ngIf="isAdmin" (click)="forgetPassword()" type="submit"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div>Reset Password</button></h4>
				<h4><button style="width: 48%;" type="submit" *ngIf="isAdminUser" (click)="forgetUserPassword()"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div> <b>Reset Password</b></button></h4>
				<div style="text-align: center">
					<img src="assets/img/NSFLogo.png" alt="user" width="70px">
				</div>
			</div>
		</form>
		<!-- <div class="inputBox">
			<input type="text">
			<label for="">Email</label> -->
		<!-- </div>
		</div>  -->
		<!-- <h4><button type="submit" (click)="varifyOtpOnSignUp()"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div><b>Submit</b></button></h4> -->
	</div>
	<div class="boxFooter">
		<!-- <h2>fdsf</h2> -->
		<img src="assets/img/logo2.png" height="30" width="160">
		<h4 class="boxFooterText">Powering<br/>Today's<br/>Workforce</h4>
		<!-- <h5>Today's</h5> -->
		<!-- <h5>Workforce</h5> -->
	</div>
</section>