<!-- <div class="preloader">
    <div class="cssload-speeding-wheel"></div>
</div> -->
<!-- <span class="text-center db" style="margin-top:2%;">
    <img src="assets/img/logo.png" alt="Home" style="width: 45%;"><br>
</span> -->
<!-- <section id="wrapper" class="login-register">
    <div class="login-box">
        <div class="white-box" style="background-color: white">
            <span class="text-center db" style="font-weight: bold;color: #3DF712;">
                Sign In As Super Admin
            </span>
            <form class="form-horizontal new-lg-form" id="login" name="login" [formGroup]="loginForm" (ngSubmit)="superAdminLogin()">
                <div class="form-group  m-t-20">
                    <div class="col-xs-12">
                        <label for="username" style="color: #3DF712;">Email</label>
                        <input class="form-control" autocomplete="off" type="email" placeholder="Enter Your Email" style="border-color: #3DF712;"
                        formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Email</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <label for="password" style="color: #3DF712;">Password</label>
                        <input class="form-control" name="password" type="password" placeholder="Enter Your Password" style="border-color: #3DF712;"
                        formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Password</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-info pull-left p-t-0">
                            <input id="checkbox-signup" type="checkbox">
                            <label for="checkbox-signup" style="color: #3DF712;"> Remember me </label>
                        </div>
                    </div>
                </div>
                <div class="form-group text-center mt-2">
                    <div class="col-xs-12">
                        <button [disabled]="loading" class="btn btn-lg btn-block text-white"  type="submit" style="background-color: #3DF712;">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> 
                            Sign In
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section> -->

<section id="wrapper" class="login-register">
	<div class="box" *ngIf="!passwordForm">
		<h4>Sign In As Super Admin</h4>
		<form [formGroup]="loginForm"  (ngSubmit)="superAdminLogin()">
			<div class="inputBox">
				<input type="text" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Email</div>
                </div>
				<label for="">Email</label>
			</div>
			<div class="inputBox">
				<input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Password</div>
                </div>
				<label for="">Password</label>
			</div>
            <p class="text-center"><a class="color" (click)="forget()">Forget Password ?</a></p>
			<h4><button type="submit"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div> <b>Submit</b></button></h4>
		</form>
	</div>
    <div *ngIf="passwordForm" class="box">
		<h4>Forget Password</h4>
		<form [formGroup]="forgetForm" (ngSubmit)="forgetPassword()">
			<div class="inputBox">
				<input type="text" formControlName="email" placeholder="Please enter registered email" [ngClass]="{ 'is-invalid': submitted && fp.email.errors }">
                <div *ngIf="submitted && fp.email.errors" class="invalid-feedback">
                    <div *ngIf="fp.email.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Email</div>
                </div>
				<label for="">Email</label>
			</div>
			<h4><button style="width: 48%;" type="submit"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div>Reset Password</button></h4>
		</form>
	</div>
    <div class="boxFooter">
		<!-- <h2>fdsf</h2> -->
		<img src="assets/img/logo2.png" height="30" width="160">
		<h4 class="boxFooterText">Powering<br/>Today's<br/>Workforce</h4>
		<!-- <h5>Today's</h5> -->
		<!-- <h5>Workforce</h5> -->
	</div>
</section>